import React from 'react'

const Banner = () => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Bioactive Molecular Technology</h1>
            </header>
            <div className="content">
                <p>When this power is harnessed for medical applications, their potential becomes greater than ever. Regenerative medicine can theoretically 
                    reverse degenerative conditions and increase lifespans by introducing a fresh supply of stem cells into the body.</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly" >Learn More</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
