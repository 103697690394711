import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import HelmetContent from '../components/HelmetContent'

import rejuvenation from '../assets/images/full-body-rejuvenation.jpg'
import msc from '../assets/images/msc.jpg'
import socials from '../assets/images/socials.jpeg'
import team from '../assets/images/the_team.jpg'

const HomeIndex = () => {
  return (
    <Layout>
      <HelmetContent title='BMT Rejuvenation' />
      <Banner />

      <div id="main">
        <section id="one" className="tiles">
          <article style={{ backgroundImage: `url(${team})` }}>
            <header className="major">
              <h3>Who are we?</h3>
              <p>We are a small but highly trained team of nurses, doctors and technicians. We utilise
              groud breaking stem cell technologies in a safe and nurchuring environment with a focus on
              improving our patients quality of life.</p>
            </header>
          </article>
          <article style={{ backgroundImage: `url(${socials})` }}>
            <header className="major">
              <h3>Social Media</h3>
              <p>Still not quite sure who we are? Visit us on our socials to view testimonials or post any questions you may have.</p>
              <br />
              <a href="https://www.facebook.com/bmtrejuvenation/" target="_blank" rel="noopener noreferrer" className="icon alt fa-facebook"><span className="label">Facebook</span></a>
            </header>
          </article>
          <article style={{ backgroundImage: `url(${msc})` }}>
            <header className="major">
              <h3>What is Bioactive Molecular Technology (BMT)?</h3>
              <p> Bioactive molecular Technology (BMT) is platelet, white cell, red cell & stem cell rich plasma that has two primary functions. <br />
                1: Self Renewal: The ability to replicate while maintaining the undifferentiated state of the stem cell. <br />
                2: Differentiation: the ability to transform into other cells of the body. Some of these cells are muscle tissues,
                cartilage, collagen and many more. Both these functions work to repair, replace, and rejuvenate damaged tissues in the body.</p>
              <ul className="actions" style={{ paddingTop: 10 }}>
                <li><a href="/bmt" className="button next scrolly" >Learn More</a></li>
              </ul>
            </header>
            <Link to="/bmt" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${rejuvenation})` }}>
            <header className="major">
              <h3>Stem Cell Rejuvenation (AMPC)</h3>
              <p>The stem cell replicates for a prolonged duration to create
                   more stem cells that then transforms into a cell type that
                   the body needs. This chain of events elicits a powerful
                   phenomenon where stem cells repair, regenerate, replace
                   damaged or worn cells in the body.</p>
              <ul className="actions" style={{ paddingTop: 10 }}>
                <li><a href="/stem-cell" className="button next scrolly" >Learn More</a></li>
              </ul>
            </header>
            <Link to="/stem-cell" className="link primary"></Link>
          </article>
        </section>
        <section id="two">
          <div className="inner">
            <header className="major">
              <h2>Procedure Costs and Pricing</h2>
            </header>
            <p>Costs will vary drastically from procedure to procedure. Please feel free to contact us at any time with your questions about pricing and
              someone from out team will contact you as soon as they can.</p>
          </div>
        </section>
      </div>

    </Layout >
  )
}

export default HomeIndex